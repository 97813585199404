import angularModule from 'Resources/angularModule/scripts/resources_module';

angularModule.factory('ResourcesAuthenticationHelper', [
    '$injector',
    $injector => {
        const $http = $injector.get('$http');
        const $window = $injector.get('$window');

        return {
            getAuthenticationToken() {
                return $http
                    .get(`${$window.ENDPOINT_ROOT}/api/student_resources/authentication_token.json`)
                    .then(resp => resp.data?.token);
            },
        };
    },
]);
