import { type ErrorLogService as ErrorLogServiceClass } from 'ErrorLogging';
import { getCompoundItemVersionKeyForStream, lessonsApi, type Stream } from 'Lessons';
import logInDevMode from 'logInDevMode';
import { type FrontRoyalStore } from 'FrontRoyalStore';
import { type AnyObject } from '@Types';
import type TimerSingleton from 'FrontRoyalTimer/TimerSingleton';
import { isDisconnectedError } from 'ReduxHelpers';
import throwIfNotEnoughStorageAvailable from './throwIfNotEnoughStorageAvailable';
import { getStreamAndEnsureStored } from './getStreamAndEnsureStored';

// This function considers all of the streams that are currently stored in frontRoyalStore, sends a request to
// the server to determine which of those have been updated since they were last stored, and then pulls the new
// versions down from the server for any that have been updated.
export async function updateOutdatedStreams(injector: ng.auto.IInjectorService) {
    const frontRoyalStore = injector.get<FrontRoyalStore>('frontRoyalStore');
    const streamsThatAreAlreadyLoaded = await frontRoyalStore.retryRequestOnHandledError('getPublishedStreams');

    if (!streamsThatAreAlreadyLoaded.length) return;

    try {
        const { streamMap, streamIdsToUpdate } = await getStreamsToUpdate({ streamsThatAreAlreadyLoaded });

        if (streamIdsToUpdate.length === 0) return;

        logInDevMode(injector, `reloading ${streamIdsToUpdate.length} outdated streams`);
        await updateStreams({ streamIdsToUpdate, streamMap, injector });
    } catch (err) {
        const error = err as AnyObject & Error;

        // If an error happens while trying to update streams, we don't need anything user-facing to happen.
        // If it's anything other than a DisconnectedError, then we want to know about it.
        if (!isDisconnectedError(error)) {
            const ErrorLogService = injector.get<typeof ErrorLogServiceClass>('ErrorLogService');
            ErrorLogService.notifyInProd(error, undefined);
        }
    }

    logInDevMode(injector, `done loading outdated streams.`);
}

async function updateStreams({
    streamIdsToUpdate,
    streamMap,
    injector,
}: {
    streamIdsToUpdate: string[];
    streamMap: Record<string, Stream>;
    injector: ng.auto.IInjectorService;
}) {
    // We're awaiting inside a loop here instead of using Promise.all for 2 reasons:
    // 1. We used to do it this way back when we were using $http and going through the HttpQueue,
    //   there's some risk in trying to change it, so I didn't want to mix that in with the refactor
    //   that moved us to RTKQuery for loading streams
    // 2. Maybe trying to load streams and images in parallel all at once could lock up browsers
    // 3. We might hit the limit of how many http connections the browser has if we're not somewhat careful.
    for (let i = 0; i < streamIdsToUpdate.length; i += 1) {
        const id = streamIdsToUpdate[i];

        // eslint-disable-next-line no-await-in-loop
        await updateStream({ id, streamMap, injector });
    }

    return true;
}

async function getStreamsToUpdate({ streamsThatAreAlreadyLoaded }: { streamsThatAreAlreadyLoaded: Stream[] }) {
    // create a map from stream ids to the stream
    const streamMap = streamsThatAreAlreadyLoaded.reduce<Record<string, Stream>>((acc, stream) => {
        acc[stream.id] = stream;
        return acc;
    }, {});

    const streamParams = streamsThatAreAlreadyLoaded.map(stream => ({
        id: stream.id,
        // This compound key allows the server to determine whether there are any changes that
        // need to be pushed down. See StreamController#get_outdated
        compoundItemVersionKey: getCompoundItemVersionKeyForStream(stream),
    }));

    let streamIdsToUpdate: string[] = [];
    const response = await lessonsApi.makeRequest('invalidateOutdatedStreams', streamParams);
    streamIdsToUpdate = response.contents.lessonStreams.map(({ id }) => id);

    return {
        streamIdsToUpdate,
        streamMap,
    };
}

async function updateStream({
    id,
    streamMap,
    injector,
}: {
    id: string;
    streamMap: Record<string, Stream>;
    injector: ng.auto.IInjectorService;
}) {
    const ErrorLogService = injector.get<typeof ErrorLogServiceClass>('ErrorLogService');
    const timerSingleton = injector.get<TimerSingleton>('timerSingleton');

    const timerKey = `stored_updated_stream:${id}`;
    timerSingleton.startTimer(timerKey);

    await throwIfNotEnoughStorageAvailable();
    const stream = await getStreamAndEnsureStored({
        id,
        ensureLessonsAndImagesStored: streamMap[id].allContentStored === 1,
        forceRefetchFromLessonsApi: true,
    });

    if (!stream) {
        ErrorLogService.notifyInProd('Did not load stream in updateOutdatedStreams', undefined, {
            id,
        });
    } else {
        timerSingleton.finishTimer(timerKey, 'stored_updated_stream', {
            lesson_stream_id: stream.id,
            message: {
                originalCompoundItemVersionKey: getCompoundItemVersionKeyForStream(streamMap[id]),
                newCompoundItemVersionKey: getCompoundItemVersionKeyForStream(stream),
            },
        });
    }
}
