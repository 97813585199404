import 'angular-bind-html-compile';

import 'EventLogger/angularModule';
import 'IsMobile/angularModule';
import 'Navigation/angularModule';
import 'Translation/angularModule';

export default angular.module('FrontRoyal.Resources', [
    'angular-bind-html-compile',
    'FrontRoyal.Navigation',
    'Translation',
    'EventLogger',
    'isMobile',
]);
