import { getBackRoyalApiBaseConfig } from 'BackRoyalApi';
import { createApi } from 'ReduxHelpers';
import { orderBy } from 'lodash/fp';
import {
    type ResourcesAPIResponse,
    type ArticleSearchParams,
    type ArticleSearchResult,
    type LibraryDatabaseAccessResponse,
} from './Resources.types';

export const ARTICLES_SEARCH_ENDPOINT = '/resources/articles/search';
export const LIBRARY_DATABASE_ACCESS_ENDPOINT = '/student_resources/library_database_access';

export const resourcesApi = createApi('resourcesApi', {
    ...getBackRoyalApiBaseConfig('resourcesApi'),
    endpoints: builder => ({
        searchArticles: builder.query<ResourcesAPIResponse<ArticleSearchResult>['articles'], ArticleSearchParams>({
            query: ({ categoryId: _categoryId, ...params }) => ({
                url: ARTICLES_SEARCH_ENDPOINT,
                params,
            }),
            // Try and manage rate limiting by caching each search for 5 minutes
            keepUnusedDataFor: 300,
            transformResponse: (
                { articles: { page, pages, items } }: ResourcesAPIResponse<ArticleSearchResult>,
                _,
                { categoryId },
            ) => {
                const filteredItems = categoryId ? items.filter(item => item.categoryIds.includes(categoryId)) : items;
                return {
                    page,
                    pages,
                    items: orderBy<ArticleSearchResult>('number')('asc')(filteredItems),
                    count: filteredItems.length,
                };
            },
        }),
        libraryDatabaseAccess: builder.query<LibraryDatabaseAccessResponse, string | undefined>({
            query: programInclusionId => {
                const params = programInclusionId ? { programInclusionId } : undefined;
                return {
                    url: LIBRARY_DATABASE_ACCESS_ENDPOINT,
                    params,
                };
            },
        }),
    }),
});

export const { useSearchArticlesQuery, useLibraryDatabaseAccessQuery } = resourcesApi;
