import angularModule from 'Resources/angularModule/scripts/resources_module';
import template from 'Resources/angularModule/views/resources.html';
import cacheAngularTemplate from 'cacheAngularTemplate';

const templateUrl = cacheAngularTemplate(angularModule, template);

angularModule.directive('resources', [
    '$injector',

    function factory($injector) {
        const $rootScope = $injector.get('$rootScope');
        const AppHeaderViewModel = $injector.get('Navigation.AppHeader.AppHeaderViewModel');
        const TranslationHelper = $injector.get('TranslationHelper');

        return {
            scope: {},
            restrict: 'E',
            templateUrl,
            controllerAs: 'controller',

            link(scope) {
                //---------------------------
                // Initialization
                //---------------------------

                Object.defineProperty(scope, 'currentUser', {
                    get() {
                        return $rootScope.currentUser;
                    },
                });

                const translationHelper = new TranslationHelper('resources.resources');

                AppHeaderViewModel.setBodyBackground('beige');
                AppHeaderViewModel.showAlternateHomeButton = false;
                AppHeaderViewModel.setTitleHTML(translationHelper.get('resources_title').toLocaleUpperCase());

                ensureHasSeenResourcesTabFlagSaved();

                //---------------------------
                // Functions
                //---------------------------

                function ensureHasSeenResourcesTabFlagSaved() {
                    if (scope.currentUser.has_seen_resources_tab) {
                        return;
                    }
                    scope.currentUser.has_seen_resources_tab = true;
                    if (!scope.currentUser.ghostMode) {
                        scope.currentUser.save();
                    }
                }
            },
        };
    },
]);
